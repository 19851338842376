<template>
    <div class="pt-1 px-3">
        <div class="">
            <div @click="goBack()" class="h4 mb-0 text-white d-flex align-items-center" style="">
                <i class="bx bx-chevron-left" style="font-size: 20px;color: #FFAD32;"></i>
                Back
            </div>
        </div>

        <div class="d-flex mt-2">
            <div class="m-auto position-relative">
                <img :src="url ? url : (pet_picture ? pet_picture : default_img)" class="bg-white" style="border-radius: 10px;height:180px;width:180px;object-fit: cover;" />
                <div @click="selectPicture()" class="position-absolute d-flex rounded-circle" style="bottom: 10px;right: 10px;background-color: #1B628C;padding:.5em">
                    <i class="bx bxs-camera m-auto" style="font-size: 18px;color: white;"></i>
                </div>
                <form id="form">
                    <input type="file" @change="onFileChange" name="pet_picture" id="user_picture" class="d-none" accept="image/*" />
                </form>
            </div>
        </div>
        <div class="d-flex mt-1">
            <div class="badge badge-danger text-white m-auto" v-if="(!url && !pet_picture) && isSubmit" 
                style="margin-top: .5em;">Pet picture not valid</div>
        </div>

        <div class="mt-2">
            <label class="h3 text-white">Name</label>
            <div class="input-group">
                <span class="input-group-text text-white"><i class="bx bx-id-card"></i></span>
                <input class="form-control" placeholder="Name" v-model="pet_name">
            </div>
            <div class="badge badge-danger text-white" v-if="!valid.pet_name && isSubmit" style="margin-top: .5em;">Pet name not valid</div>
        </div>
        
        <div class="mt-2">
            <label class="h3 text-white">Breed</label>
            <div class="input-group">
                <span class="input-group-text text-white"><i class="bx bxs-dog"></i></span>
                <input class="form-control" placeholder="Breed" v-model="pet_breed">
            </div>
            <div class="badge badge-danger text-white" v-if="!valid.pet_breed && isSubmit" style="margin-top: .5em;">Pet breed not valid</div>
        </div> 
        
        <div class="mt-2">
            <label class="h3 text-white">Gender</label>
            <div class="input-group">
                <span class="input-group-text text-white"><i class="bx bx-male-female"></i></span>
                <input class="form-control" placeholder="Gender" v-model="pet_gender">
            </div>
            <div class="badge badge-danger text-white" v-if="!valid.pet_gender && isSubmit" style="margin-top: .5em;">Pet gender not valid</div>
        </div> 
        
        <div class="mt-2">
            <label class="h3 text-white">Age (Months)</label>
            <div class="input-group">
                <span class="input-group-text text-white"><i class="bx bx-calendar-heart"></i></span>
                <input class="form-control" placeholder="Age" type="number" v-model="pet_age">
            </div>
            <div class="badge badge-danger text-white" v-if="!valid.pet_age && isSubmit" style="margin-top: .5em;">Pet age not valid</div>
        </div> 

        <div class="mt-2">
            <label class="h3 text-white">Special Needs</label>
            <div class="input-group">
                <span class="input-group-text text-white"><i class="bx bx-notepad"></i></span>
                <input class="form-control" placeholder="Special Needs" v-model="pet_special_needs" />
            </div>
        </div> 

        <div class="mt-3 w-100 d-flex flex-column justify-content-center" style="margin-bottom: 3em;gap:10px">
            <button class="btn text-white flex-fill" @click="onSubmit()" style="background-color: #187294;height:50px;font-size: 18px;">
                Save Pet Info
            </button> 
        </div>

    </div>
</template>

<script>

import router from '@/router'
import store from '@/store'
import Cookies from 'universal-cookie'
const cookies = new Cookies()

export default {
    computed: {
        pet_info(){
            return store.state.pet.PET_INFO
        },
        user(){
            return store.state.auth.USER
        },
        valid(){
            return {
                pet_name : this.pet_name?.length > 1,
                pet_breed : this.pet_breed?.length > 1,
                pet_gender : this.pet_gender?.length > 1,
                pet_age : this.pet_age?.toString()?.length >= 1,
                picture: this.url || this.pet_picture
            }
        },
        allValid(){
            let keys = Object.keys(this.valid)
            let valid = true
            keys.forEach(item => {
                valid = valid && this.valid[item]
            })
            return valid
        }
    },
    watch: {
        pet_info(val){
            if(val.user_id != this.user.user_id){
                this.$router.push('/homepage')
            }

            this.pet_name = val.pet_name
            this.pet_age = val.pet_age
            this.pet_breed = val.pet_breed
            this.pet_gender = val.pet_gender
            this.pet_special_needs = val.pet_special_needs
            this.pet_picture = val.pet_picture
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        onFileChange(e) {
            const file = e.target.files[0];
            this.url = URL.createObjectURL(file);
        },
        selectPicture(){
            $('#user_picture').click()
        },
        onSubmit(){
            this.isSubmit = true
            if(this.allValid){
                let formData = new FormData($('#form')[0])
                formData.append('inp[pet_name]', this.pet_name)
                formData.append('inp[pet_breed]', this.pet_breed)
                formData.append('inp[pet_gender]', this.pet_gender)  
                formData.append('inp[pet_age]', this.pet_age)  
                formData.append('inp[pet_special_needs]', this.pet_special_needs)  
                formData.append('inp[user_id]', cookies.get('token'))  
                if(this.pet_id)
                formData.append('id', this.pet_id)
                store.dispatch('pet/SavePet', formData).then(res => {
                    console.log(res)
                    if(this.pet_id){
                        router.go(0)
                    } else {
                        console.log(res)
                        router.push('/profile/form-pet/'+res.id)
                    }
                })
            }
        }
    },
    mounted(){
        if(this.pet_id){
            store.dispatch('pet/GetPet', this.pet_id)
        }
    },
    data(){
        return {
            pet_id : this.$route.params.id,
            pet_name: '',
            pet_breed: '',
            pet_gender: '',
            pet_age: 0,
            pet_special_needs: '',
            pet_picture: '',
            isSubmit: false,
            url: null,
            default_img : 'https://qompair.com/dog.png',
        }
    }
}

</script>

<style>
    .auth-page .btn {
        border-radius: 20px;
    }

    input:focus {
        color: white !important;
    }

    .input-group span {
        border: unset;
        border-radius: unset;
        border-bottom: 1px solid white;
        font-size: 24px;
        color: #1F9BCD;
        background-color: transparent;
    }
    .input-group input {
        margin-left: 0;
        padding-left: 0;
        border: unset;
        border-radius: unset;
        border-bottom: 1px solid white;
        height: 40px;
        color: white;
        background-color: transparent !important;
    }  
    select.form-control option, select.form-control:active option {
        color:white !important;
        background-color: #1B628C !important;
    }
</style>